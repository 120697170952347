import React, { useEffect, useState } from 'react';

import { ReactComponent as CartIcon } from '@assets/image/svg/cart_white.svg';
import { Button } from '@components/controls/button';
import { StyledSelect } from '@components/controls/select';
import { Block } from '@components/layout/block';
import { Grid } from '@components/layout/grid';
import { HStack } from '@components/layout/hstack';
import { Paragraph } from '@components/typographies/paragraph';
import { Span } from '@components/typographies/span';
import { useDetectMedia } from '@hooks/useDetectMedia';
import { handleClick } from '@lib/handlers';
import { useCartParams } from '@redux/slices/cartParamSlice';

const maxCount = 100;

type Props = {
  id: string;
};

export const AddCartForm = ({ id }: Props) => {
  const [itemCount, setItemCount] = useState<number>(1);
  const { addCartParam } = useCartParams();
  const { md } = useDetectMedia();
  const [isItemAdded, setItemAdded] = useState<boolean>(false);

  const onClickRegisterCart = handleClick(() => {
    addCartParam(Number(id), itemCount);
    setItemAdded(true);
  });

  useEffect(() => {
    if (isItemAdded) {
      const timeout = setTimeout(() => {
        setItemAdded(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isItemAdded]);

  return (
    <Block width="100%" md={{ width: '550px' }}>
      <Grid
        gridTemplateColumns={'1fr'}
        alignItems="center"
        justify="center"
        gap="17px"
        md={{
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'center',
          justify: 'center',
          gap: '38px',
        }}
      >
        <HStack spacing="1em">
          <Span fontSize="19px" fontWeight="bold" whiteSpace="nowrap">
            個数
          </Span>
          <StyledSelect
            width="60px"
            height="30px"
            padding="0 0 0 15px"
            value={String(itemCount)}
            onChange={(e) => {
              setItemCount(Number(e.target.value));
            }}
          >
            {Array.from({ length: maxCount }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </StyledSelect>
          <Span fontSize="19px" fontWeight="bold">
            個
          </Span>
        </HStack>
        {isItemAdded ? (
          <Block
            display="flex"
            alignItems="center"
            width={md ? '300px' : '100%'}
            height={md ? '85px' : '44px'}
            bg="skin"
            border="2px solid var(--color-orange)"
            borderRadius={md ? '15px' : '5px'}
          >
            <Paragraph align="center" color="orange" fontSize="19px" fontWeight="700">
              カートに追加されました
            </Paragraph>
          </Block>
        ) : (
          <Button
            onClick={onClickRegisterCart}
            width={md ? '300px' : '100%'}
            height={md ? '85px' : '44px'}
            color="white"
            bg="orange"
            radius={md ? '15px' : '5px'}
          >
            <CartIcon />
            &nbsp;
            <Paragraph
              align="center"
              color="white"
              fontSize="19px"
              fontWeight="700"
              width="fit-content"
            >
              カートに追加する
            </Paragraph>
          </Button>
        )}
      </Grid>
    </Block>
  );
};
