import React, { FC } from 'react';
import styled from 'styled-components';

import { Card } from '@components/layout/card';

type Props = {
  tags: string[];
};

export const TagComponent: FC<Props> = ({ tags }) => (
  <TagCard bg="white">
    {tags.map((s) => (
      <TagItem key={`tags-${s}`} className="tag">
        #{s}
      </TagItem>
    ))}
  </TagCard>
);

const TagCard = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const TagItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--color-bg-gray2);
  padding: 10px;
  font-size: 14px;
`;
