import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';

import { ShopInfoContainerStyle, ShopInfoStyle } from './style';

import { Link } from '@components/typographies/link';
import { Shop } from '@pb/api/v1/user/shop/shop_pb';

type Props = {
  shop: Shop.AsObject;
  align?: 'start' | 'center' | 'end';
};

const ShopInfoComponent: FC<Props> = ({ shop, align = 'start' }) => {
  return (
    <ShopInfoContainerStyle>
      <h2 className="shop-info-header">
        <FormattedMessage id="shop.info" />
      </h2>
      <Link to={`/shop/${shop.id}`}>
        <ShopInfoStyle align={align}>
          <img src={shop?.logo?.url} alt={shop?.logo?.name} />
          <div className="info">
            <h2>{shop?.name}</h2>
            <div className="d-inline-flex p">
              {/* <Stars
              hoverIndex={(comments || []).map((c) => c.stars).reduce((sum, c) => sum + c, 0) / (comments?.length || 1)}
              width="15px"
            /> */}
            </div>
          </div>
        </ShopInfoStyle>
      </Link>
      {/* <p>{shop.description}</p>
      <ShopProductComponent products={shopProducts || []} /> */}
    </ShopInfoContainerStyle>
  );
};

export default React.memo(ShopInfoComponent);
