import { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import { Helmet } from 'react-helmet';

import ItemDetailComponent from '../ItemDetailComponent';
import { TagComponent } from '../TagComponent';

import { AddCartForm } from './AddCartForm';
import { ItemImageGallery } from './ItemImageGallery';
import { ItemTags } from './ItemTags';
import { ShippingFeeSection } from './ShippingFeeSection';
import { ShopInfo } from './ShopInfo';

import { useShopDetail } from '@api/shop/useShopDetail';
import { useItemDetail } from '@apis/items/useItemDetail';
import { Collapse } from '@components/common';
import { title } from '@components/layout/SEO';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { Grid } from '@components/layout/grid';
import { Paragraph } from '@components/typographies/paragraph';
import { Span } from '@components/typographies/span';
import ShopInfoComponent from '@features/shop/ShopInfoComponent';
import { useDetectMedia } from '@hooks/useDetectMedia';
import { useIsMounted } from '@hooks/useIsMounted';

type Props = {
  id?: string;
} & RouteComponentProps;

const ItemDetail = ({ id }: Props) => {
  const { ref, isMounted } = useIsMounted<HTMLDivElement>();
  const { item } = useItemDetail(isMounted ? Number(id) : undefined);
  const { shop } = useShopDetail(item?.shopid);
  const { md } = useDetectMedia();

  return (
    <Block
      ref={ref}
      padding={md ? '26px 73px 0 73px' : '0px 10px'}
      marginTop={md ? '0px' : '-30px'}
      maxWidth="100vw"
      color="var(--color-navy)"
    >
      {item && shop && (
        <Helmet>
          <title>
            {item.itemName} | {shop.name} | {title}
          </title>
          <meta name="description" content={item.description} />
          {/* FIXME: OGPはhelmetでは意味がないのでgatsby-node & Head API で生成するか、Next.jsに置き換えてSSRする */}
          {/* <meta name="og:image" content={item.itemImageList[0]?.path} /> */}
          {/* <meta name="og:title" content={`${item.itemName} | ${shop.name} | Tastyモール`} /> */}
          {/* <meta name="og:description" content={item.description} /> */}
        </Helmet>
      )}
      <Grid
        gridTemplateColumns="100%"
        md={{
          gridTemplateColumns: '50% 50%',
          gap: '42px',
        }}
      >
        {item ? <ItemImageGallery item={item} /> : <div />}
        <Flex
          jc="flex-start"
          alignItems="flex-start"
          direction="column"
          margin={md ? '0 0 0 0' : '19px 0 0 0'}
        >
          {md && <Block paddingBottom="10px">{item && <ShopInfo item={item} md={md} />}</Block>}
          <Block paddingBottom={md ? '10px' : '6px'}>
            <Paragraph
              color="black"
              fontSize={md ? '30px' : '18px'}
              fontWeight="bold"
              lh={md ? '45px' : '27px'}
            >
              {item?.itemName}
            </Paragraph>
          </Block>
          {!md && <Block paddingBottom="30px">{item && <ShopInfo item={item} md={md} />}</Block>}
          <Block paddingBottom={md ? '20px' : '13px'}>
            <Span fontFamily="Hiragino Kaku Gothic ProN" fontWeight="bold" fontSize="30px">
              ￥{item?.price.toLocaleString()}
            </Span>
            <Span>（税込）</Span>
          </Block>
          {item && <ItemTags item={item} direction={md ? 'row' : 'column'} />}
          {md && <hr />}
          <Block padding={md ? '12px 0 50px 0' : '0 0 20px 0'}>
            <Paragraph color="black" fontSize="16px" lh="23px" whiteSpace="pre-wrap">
              {item?.description}
            </Paragraph>
          </Block>
          {!md && <hr />}
          {shop && (
            <Block padding={md ? '0 0 39px 0' : '30px 0 49px 0'}>
              <ShippingFeeSection shop={shop} />
            </Block>
          )}
          {id && isMounted && (
            <Block paddingBottom={md ? '39px' : '36px'} width="100%">
              <AddCartForm id={id} />
            </Block>
          )}
          {md && <hr />}
          {item && (
            <Block paddingTop={md ? '25px' : '0'}>
              <TagComponent tags={item.tagList} />
            </Block>
          )}
        </Flex>
      </Grid>
      {/* <RelativeProductComponent /> */}
      <Block height="100px" />
      {item && (
        <Collapse title="商品の詳細" margin="0">
          <ItemDetailComponent item={item} />
        </Collapse>
      )}
      {/* <Collapse title="発送に関して" margin="0" hideBorderBottom>
        <Center height="200px" width="100%">
          <Paragraph>ここに文章が入ります</Paragraph>
        </Center>
      </Collapse>
      <Collapse title="注意事項" margin="0">
        <NoticeComponent />
      </Collapse> */}
      {/** TODO: ShopInfoComponentはAPI不足のため連携未対応 */}
      {shop && <ShopInfoComponent shop={shop} />}
      {/* <Center>
        <Block maxWidth="630px">
          <Calendars
            title="営業カレンダー"
            dates={[
              new Date().toLocaleDateString(),
              new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString(),
            ]}
            shippingDay={[]}
            inquiriesDay={[]}
            holiday={[]}
          />
        </Block>
      </Center> */}
    </Block>
  );
};

export default ItemDetail;
