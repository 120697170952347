import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';

import { ItemContent, ItemDetailRow, ItemTitle } from './ItemDetailRow';

import { VStack } from '@components/layout/vstack';
import { useDetectMedia } from '@hooks/useDetectMedia';
import { DetailResponse } from '@pb/api/v1/user/item/item_pb';

type Props = {
  item: DetailResponse.Item.AsObject;
};

const keyIndex = {
  shopName: 'item.shop.name',
  allergens: 'item.allergens',
  origin: 'item.origin',
  material: 'item.material',
  preservation: 'item.preservation',
  manufacturer: 'item.manufacturer',
  capacity: 'item.capacity',
};

const ItemDetailComponent: FC<Props> = ({ item }) => {
  const { md } = useDetectMedia();

  return (
    <VStack align="flex-start" spacing={md ? '50px' : '30px'} px={md ? '100px' : '20px'}>
      <ItemDetailRow>
        <ItemTitle>
          <FormattedMessage id={keyIndex.shopName} />
        </ItemTitle>
        <ItemContent>
          <a target="_blank" href={`/shop/${item.shopid}`} rel="noreferrer">
            {item.shopName}
          </a>
        </ItemContent>
      </ItemDetailRow>
      <ItemDetailRow>
        <ItemTitle>
          <FormattedMessage id={keyIndex.allergens} />
        </ItemTitle>
        <ItemContent>{item.allergyList.join(', ')}</ItemContent>
      </ItemDetailRow>
      <ItemDetailRow>
        <ItemTitle>
          <FormattedMessage id={keyIndex.origin} />
        </ItemTitle>
        <ItemContent>{item.manufacture?.prefecture ?? ''}</ItemContent>
      </ItemDetailRow>
      <ItemDetailRow>
        <ItemTitle>
          <FormattedMessage id={keyIndex.material} />
        </ItemTitle>
        <ItemContent>{item.materialList.join(', ')}</ItemContent>
      </ItemDetailRow>
      <ItemDetailRow>
        <ItemTitle>
          <FormattedMessage id={keyIndex.preservation} />
        </ItemTitle>
        <ItemContent>{item.preservation}</ItemContent>
      </ItemDetailRow>
      <ItemDetailRow>
        <ItemTitle>
          <FormattedMessage id={keyIndex.manufacturer} />
        </ItemTitle>
        <ItemContent>{item.manufacture?.municipality ?? ''}</ItemContent>
      </ItemDetailRow>
      <ItemDetailRow>
        <ItemTitle>
          <FormattedMessage id={keyIndex.capacity} />
        </ItemTitle>
        <ItemContent>
          {item.volume}&nbsp;{item.volumeUnit}
        </ItemContent>
      </ItemDetailRow>
    </VStack>
  );
};

export default ItemDetailComponent;
