import React, { useState } from 'react';
import styled from 'styled-components';

import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { breakpoint } from '@constants/theme';
import { DetailResponse } from '@pb/api/v1/user/item/item_pb';

type Props = {
  item: DetailResponse.Item.AsObject;
};

export const ItemImageGallery = ({ item }: Props) => {
  const [pickUpImage, setPickUp] = useState(item.itemImageList[0].path);
  const images = item.itemImageList.map((image) => image.path);

  return (
    <Block md={{ width: '538px' }} width="100%" margin="0 auto">
      <PickUp
        src={
          pickUpImage ||
          'https://tastymall-dev-assets.s3.ap-northeast-1.amazonaws.com/images/item/tasty_item_no_image.png'
        }
        alt=""
      />
      <Flex flexWrap="wrap" jc="flex-start">
        {images?.map((url) => (
          <GalleryItem src={url} key={url} onClick={() => setPickUp(url)} />
        ))}
      </Flex>
      {/* <ShareComponent /> */}
    </Block>
  );
};

const PickUp = styled.img`
  width: 100%;
  max-width: 538px;
  aspect-ratio: 1 / 1;
  object-fit: contain;

  @media (min-width: ${breakpoint.md}px) {
    border-radius: 20px;
  }
`;

const GalleryItem = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 20px;
  margin: 2px;
  object-fit: cover;
  @media (max-width: ${breakpoint.md}px) {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    object-fit: cover;
  }
`;
