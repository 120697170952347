import { Router } from '@gatsbyjs/reach-router';
import { PageProps } from 'gatsby';
import React, { FC, Suspense } from 'react';

import { Layout } from '@components/layout';
import { Loading } from '@components/loading';
import ItemDetail from '@features/items/detail';

const ItemDetailPage: FC<PageProps> = ({ params }) => {
  const id = params.id;

  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Router basepath="/items">
          <ItemDetail id={id} path=":id" />
        </Router>
      </Suspense>
    </Layout>
  );
};

export default ItemDetailPage;
