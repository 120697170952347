import React from 'react';

import { ShopDetail } from '@api/shop/useShopDetail';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { HStack } from '@components/layout/hstack';
import { VStack } from '@components/layout/vstack';
import { Paragraph } from '@components/typographies/paragraph';
import { Span } from '@components/typographies/span';

type Props = {
  shop: ShopDetail;
};

export const ShippingFeeSection = ({ shop }: Props) => {
  return (
    <section>
      <HStack spacing="5px" align="flex-start">
        <VStack spacing="12px" align="flex-start">
          <Block paddingBottom="20px">
            <Paragraph>
              <Span
                border="1px solid var(--color-orange)"
                color="var(--color-orange)"
                padding="4px"
              >
                送料
              </Span>{' '}
              {shop?.shopShippingFee?.shippingFeeMin.toLocaleString()}〜
              {shop?.shopShippingFee?.shippingFeeMax.toLocaleString()}円
            </Paragraph>
          </Block>
          <Flex>
            {shop?.shopShippingFee?.freeShippingStatus === 2 && (
              <Flex alignItems="center">
                <Paragraph fontSize="15px" lh="18px" color="black">
                  税込{shop?.shopShippingFee?.freeShippingFee.toLocaleString()}円以上のお買い物で
                  <Span color="var(--color-orange)">送料無料</Span>！
                </Paragraph>
              </Flex>
            )}
          </Flex>
          <Paragraph lh="18px" my="8px">
            ※離島・一部地域を除く
          </Paragraph>
        </VStack>
      </HStack>
    </section>
  );
};
