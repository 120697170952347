import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

export const ItemDetailRow = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 10px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 70px;
  @media screen and (max-width: ${breakpoint.md}px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;

export const ItemTitle = styled.div`
  font-weight: 600;
  display: inline-block;
  width: 200px;
  color: var(--color-navy);

  @media screen and (max-width: ${breakpoint.md}px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const ItemContent = styled.div`
  display: block;
  width: 100%;
  color: var(--color-navy);

  div {
    margin-bottom: 0.5rem;
  }

  a {
    color: var(--color-navy);
  }
`;
