import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

export const ShopInfoContainerStyle = styled.div`
  display: block;
  box-sizing: border-box;
  margin: 2rem 0px;
  padding: 0.5rem 0.75rem;
  width: 100%;

  .shop-info-header {
    background: #f4f3f2;
    display: block;
    padding: 0.75rem;
    text-align: center;
    font-family: 'Hiragino Kaku Gothic ProN';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #3b414a;
  }
`;

export const ShopInfoStyle = styled.div<{ align: 'start' | 'center' | 'end' }>`
  display: inline-flex;
  width: 100%;
  padding: 1rem 0.25rem;
  box-sizing: border-box;
  font-family: 'Hiragino Kaku Gothic ProN';
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  color: #3b414a;
  align-items: center;
  justify-content: ${(props) => props.align || 'start'};
  text-align: left;

  img {
    display: block;
    object-fit: contain;
    width: 200px;
    height: 200px;
    border-radius: 10px;

    @media screen and (max-width: ${breakpoint.md}px) {
      width: 100px;
      height: 100px;
    }
  }

  .info {
    line-height: 27px;
    padding: 0.5rem 1rem;
  }
`;
